import React from 'react';
import ghost from './img/ghost-icon.svg';
import './index.scss';

const Footer = () => {
  return (
    <footer>
      <div className="footer-bar">
        <p>© Mostly Ghosts Media {new Date().getFullYear()}</p>
      </div>
      <img src={ghost} className="ghost-icon" />
    </footer>
  );
};

export default Footer;
