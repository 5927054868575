import React from 'react';
import logo from './img/mostly-ghosts-logo.svg';
import './index.scss';

const Header = () => (
  <header>
    <img src={logo} className="header-logo" />
    <div className="hamburger-menu">
      <h2>MENU</h2>
      <div className="hamburger-button">
        <div className="hamburger-bar" />
        <div className="hamburger-bar" />
        <div className="hamburger-bar" />
      </div>
    </div>
  </header>
);

export default Header;
