import React from 'react';
import PropTypes from 'prop-types';
import Footer from './footer';
import Header from './header';
import './layout.scss';

const Layout = ({ children }) => {
  return (
    <div className="body-container">
      <Header />
      <main>{children}</main>
      <Footer />
    </div>
  );
};

Layout.propTypes = {
  children: PropTypes.node.isRequired,
};

export default Layout;
